import React, { CSSProperties, PropsWithChildren } from 'react';
import { useStyles } from './styles';

export type ScreenContainerParams = PropsWithChildren & {
  mode?: 'full' | 'content';
  style?: CSSProperties;
  styleSubContainer?: CSSProperties;
}

const ScreenContainer = (props: ScreenContainerParams) => {
  const styles = useStyles(props);

  const stylesContainer = { ...styles.container, ...(props.style || {}) };
  const stylesSubContainer = { ...styles.subContainer, ...(props.styleSubContainer || {}) };

  return (
    <div {...props} id="screen-container" style={stylesContainer}>
      <div style={stylesSubContainer}>
        {props.children || <></>}
      </div>
    </div>
  );
}

export default ScreenContainer;
