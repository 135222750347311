import React, { useEffect } from 'react';
import './App.css';
import Header from './components/header/header';
import MissionsProjects from './blocks/missions-projects/missions-projects';
import Content from './components/content/content';

function App() {
  useEffect(() => {
    document.title = 'Missões - Igreja Batista Ágape (Regional MG)';
  }, []);

  return (
    <div className="App">
      <Header />
      <Content>
        <MissionsProjects />
      </Content>
    </div>
  );
}

export default App;
