
export const useStyles = (): any => {
  return {
    "header": {
      "display": "flex",
      "flex-grow": 1,
      "flex-direction": "column",
      "justify-content": "center",
    },
    "topContainer": {
      "height": "50px",
      "background-color": "#246F40",
    },
    "bottomContainer": {
      "display": "flex",
      "flex-direction": "row",
      "height": "100px",
      "background-color": "#FFF",
      "padding": "10px",
      "box-sizing": "border-box",
    },
    "bottomSubContainer": {
      "display": "flex",
      "flex-direction": "row",
    },
    "leftContainer": {
      "display": "flex",
      "width": "25%",
      "background-color": "#FFF",
      "justify-content": "center",
      "aligh-items": "center",
    },
    "rightContainer": {
      "display": "flex",
      "flex-direction": "column",
      "width": "80%",
      "background-color": "#FFF",
      "justify-content": "center",
      "aligh-items": "center",
      "aligh-content": "center",
      "justify-items": "center",
    },
    "logoImage": {
      "width": "236px",
      "height": "77.88",
    },
    "panelContainer": {
      "display": "flex",
      "flex-grow": 1,
      "height": "501px",
      "flex-direction": "row",
      "background-color": "#FFF",
      "overflow-x": "hidden",
      "overflow-y": "hidden",
      "position": "relative",
    },
    "panelVideo": {
      "position": "absolute",
      "top": "50%",
      "left": "50%",
      "transform": "translate(-50%,-50%)",
      "width": "1792px",
      "height": "1008px",
    },
  }
}
