
export const useStyles = (): any => {
  return {
    "container": {
      "display": "flex",
      "flex-grow": 1,
    },
    "subContainer": {
      "display": "flex",
      "flex-grow": 1,
      "flex-direction": "column",
    },
    "titleContainer": {
      "display": "flex",
      "width": "100%",
      "justify-content": "center",
      "align-content": "center",
      "align-items": "center",
    },
    "title": {
      "color": "#246F40",
      "fontSize": "56px",
      "fontWeight": "700"
    },
    "projectListContainer": {
      "display": "flex",
      "width": "100%",
      "justify-content": "center",
      "align-content": "center",
      "align-items": "center",
    },
    "projectItemContainer": {
      "display": "flex",
      "flex-direction": "column",
      "width": "25%",
      "justify-content": "center",
      "align-content": "center",
      "align-items": "center",
      "box-sizing": "border-box",
      "margin": "10px",
    },
    "projectTitleContainer": {
      "display": "flex",
      "width": "100%",
      "justify-content": "center",
      "align-content": "center",
      "align-items": "center",
    },
    "projectTitle": {
      "color": "#246F40",
      "fontSize": "20px",
      "fontWeight": "700",
      "textTransform": "uppercase",
      "margin": "10px",
    },
    "projectImageContainer": {
      "display": "flex",
      "width": "100%",
      "justify-content": "center",
      "align-content": "center",
      "align-items": "center",
    },
    "projectImage": {
    },
  }
}
