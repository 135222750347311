import { ScreenContainerParams } from "./screen-container"

export const useStyles = (props: ScreenContainerParams): any => {
  return {
    container: {
      "display": "flex",
      "flex-grow": 1,
      "justify-content": "center",
    },
    subContainer: {
      "display": "flex",
      width: props.mode === 'full' ? '100%' : "67%",
    },
  }
}
