import React, { PropsWithChildren } from 'react';
import { useStyles } from './styles';
import ScreenContainer from '../container/screen-container';

export type ContentProps = PropsWithChildren;

function Content({ children }: ContentProps) {
  const styles = useStyles();

  return (
    <ScreenContainer style={styles.container}>
      {children}
    </ScreenContainer>
  );
}

export default Content;
