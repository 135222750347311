import React from 'react';
import { useStyles } from './styles';
import ScreenContainer from '../container/screen-container';

function Header() {
  const styles = useStyles();

  return (
    <div style={styles.header}>
      <ScreenContainer style={styles.topContainer}></ScreenContainer>
      <ScreenContainer style={styles.bottomContainer} styleSubContainer={styles.bottomSubContainer}>
        <>
          <div style={styles.leftContainer}>
            <img src="https://www.missoesagape.com.br/wp-content/uploads/2022/09/Logo_Missoes.png" alt='Missões Ágape' style={styles.logoImage} />
          </div>
          <div style={styles.rightContainer}>
            Menu
          </div>
        </>
      </ScreenContainer>
      <ScreenContainer mode='full' style={styles.panelContainer}>
        <iframe
          frameBorder={0}
          allowFullScreen={true}
          allow="autoplay"
          title="Missões Ágape"
          src="https://www.youtube.com/embed/7VyutkfB3Ww?controls=0&rel=0&playsinline=1&autoplay=1"
          style={styles.panelVideo} />
      </ScreenContainer>
    </div>
  );
}

export default Header;
