
export const useStyles = (): any => {
  return {
    "container": {
      "width": "100%",
      "display": "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "justify-items": "center",
      "align-items": "center",
      "align-content": "center",
    },
  }
}
