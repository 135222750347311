import React from 'react';
import { useStyles } from './styles';
import ScreenContainer from '../../components/container/screen-container';

function MissionsProjects() {
  const styles = useStyles();

  return (
    <ScreenContainer style={styles.container} styleSubContainer={styles.subContainer}>
      <>
        <div style={styles.titleContainer}>
          <h1 style={styles.title}>Missões Ágape</h1>
        </div>
        <div style={styles.projectListContainer}>
          <div style={styles.projectItemContainer}>
            <div style={styles.projectTitleContainer}>
              <h5 style={styles.projectTitle}>PROJETO EVANGELIZART</h5>
            </div>
            <div style={styles.projectImageContainer}>
              <img src="https://www.missoesagape.com.br/wp-content/uploads/2022/10/img-missao-locao.jpeg" alt='Projeto EvangelizArt' />
            </div>
          </div>
          <div style={styles.projectItemContainer}>
            <div style={styles.projectTitleContainer}>
              <h5 style={styles.projectTitle}>PROJETO EVANGELIZART</h5>
            </div>
            <div style={styles.projectImageContainer}>
              <img src="https://www.missoesagape.com.br/wp-content/uploads/2022/10/img-missao-locao.jpeg" alt='Projeto EvangelizArt' />
            </div>
          </div>
          <div style={styles.projectItemContainer}>
            <div style={styles.projectTitleContainer}>
              <h5 style={styles.projectTitle}>PROJETO EVANGELIZART</h5>
            </div>
            <div style={styles.projectImageContainer}>
              <img src="https://www.missoesagape.com.br/wp-content/uploads/2022/10/img-missao-locao.jpeg" alt='Projeto EvangelizArt' />
            </div>
          </div>
          <div style={styles.projectItemContainer}>
            <div style={styles.projectTitleContainer}>
              <h5 style={styles.projectTitle}>PROJETO EVANGELIZART</h5>
            </div>
            <div style={styles.projectImageContainer}>
              <img src="https://www.missoesagape.com.br/wp-content/uploads/2022/10/img-missao-locao.jpeg" alt='Projeto EvangelizArt' />
            </div>
          </div>
        </div>
      </>
    </ScreenContainer>
  );
}

export default MissionsProjects;
